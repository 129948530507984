(function ($, Drupal) {
	Drupal.behaviors.dhat = {
		attach: function(context, settings) {

		}
	};
	// Offcanvas menu
	Drupal.behaviors.offcanvas = {
		attach: function(context, settings) {
			var $offCanvasToggle = $('.nav-toggle, .scrim'),
				$page = $('[role="document"]');

			$('body', context).once('load', function() {
				$offCanvasToggle.on('click', function(e) {
					$page.toggleClass('offcanvas-show');
				});
			});
		}
	};
	// Topbar
	Drupal.behaviors.topbar = {
		attach: function(context, settings) {
			// Menu functionality for touch devices
			$('body', context).once('ready', function() {
				document.addEventListener('touchend', function(e) {
					var $linkWrapper = $(e.target.parentNode),
						nextNode = e.target.nextSibling,
						nextNodeFixed = false;

					if(nextNode) {
						nextNodeFixed = (nextNode.nodeName === '#text') ? nextNode.nextSibling : nextNode;
					}
					if($('html').hasClass('touchevents') && $(nextNodeFixed).hasClass('sub-links') && window.innerWidth > 768) {
						$('.top-link-wrapper').removeClass('active');
						if($linkWrapper.hasClass('top-link-wrapper')) {
							e.preventDefault();
							$linkWrapper.addClass('active');
						}
					}else if(!$linkWrapper.hasClass('link-wrapper')) {
						$('.top-link-wrapper').removeClass('active');
					}
				});


				var raf = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame || window.msRequestAnimationFrame,
					scrollPos = 0,
					tick = false;

				function scroll() {
					scrollPos = window.scrollY;
					rafTick();
				}

				function scrollUpdate() {
					tick = false;
					var currScrollPos = scrollPos;
					if(currScrollPos > 0) {
						$('body').addClass('not-top');
					}else {
						$('body').removeClass('not-top');
					}
				}

				function rafTick() {
					if(!tick) {
						raf(scrollUpdate);
					}
					tick = true;
				}

				document.addEventListener('scroll', scroll, false);
			});
		}
	};
	// Marinas map loader
	Drupal.behaviors.marinas_map = {
		attach: function(context, settings) {

			$('.marinas-map').once('map-processed', function() {

				var map = $('.marinas-map')[0],
					mapHQ = new Image();

				mapHQ.onload = function() {
					map.src = this.src;
				}

				mapHQ.src = $('.marinas-map').data('load');
			});

		}
	};
})(jQuery, Drupal);